// themecolor
//$themecolor: #ef5488;
$themecolor: #0066CC;

//$primarybtn: #ef5488;
$primarybtn:#0066CC;

$white: #fff;

$mate-black: #5a3f3f;

$black: #474646;

//$gradient-one: #ef5488;
$gradient-one: #0066CC;

$gradient-two: #6c245a;

$footer-back-color:#000;

$header-top-color:#000;

$breadcrumbs-color:#000;
